import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { getCityData } from '../utils/city-data';
import Layout from '../components/layout/Layout';
import CustomInput from '../components/form/CustomInput';
import {
    useSendVerificationPhoneMutation,
    useRequestUserDeleteMutation,
} from '../modules/orders/api';

const cityConfig = {
    'ahtubinsk': {
        city: 'Ahtubinsk',
        appName: 'SunPanda Ахтубинск',
    },
    'krymsk': {
        city: 'Krymsk',
        appName: 'SunPanda Крымск',
    },
    'novoshahtinsk': {
        city: 'Novoshahtinsk',
        appName: 'SunPanda Новошахтинск',
    },
    'tvoya-eda': {
        city: 'KamenskShahtinsk',
        appName: 'Твоя Еда Знаменск',
    },
    'kafe-punsh': {
        city: 'Znamensk',
        appName: 'Кафе Пунш',
    },
    'sarapul': {
        city: 'Sarapul',
        appName: 'SunPanda Сарапул',
    },
    'eysk': {
        city: 'Eysk',
        appName: 'SunPanda Ейск',
    },
    'sushiland': {
        city: 'UlanUde',
        appName: 'Sushi Land Знаменск',
    },
};

function RequestForUserDeleteCityPage() {
    const { cityParam } = useParams();
    const currentCity = cityConfig[cityParam];

    const [formData, setFormData] = useState({
        city: currentCity.city,
        phone: '',
    });
    const [cityData, setCityData] = useState(null);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [secretCode, setSecretCode] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [sendVerificationPhone] = useSendVerificationPhoneMutation();
    const [requestUserDelete] = useRequestUserDeleteMutation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSendCode = async () => {
        if (!cityData) {
            const cityData = getCityData(formData.city);
            setCityData(cityData);
        }

        try {
            const response = await sendVerificationPhone({
                city: cityData.url,
                phone: formData.phone
            });
            setSecretCode(response.data.SecretCode);
            setIsCodeSent(true);
        } catch (error) {
            console.error('Error sending code:', error);
        }
    };

    const handleConfirmCode = async () => {
        try {
            if (parseInt(verificationCode, 10) === parseInt(secretCode, 10)) {
                localStorage.setItem('savedPhoneNumber', formData.phone);

                handleSuccessfulVerification();
            } else {
                alert('Код подтверждения неверный');
            }
        } catch (error) { }
    };

    const handleSuccessfulVerification = async () => {
        setIsCodeSent(false);
        setIsUserLoggedIn(true);
    };

    const handleExit = () => {
        setIsCodeSent(false);
        setIsUserLoggedIn(false);
        setVerificationCode('');

        localStorage.removeItem('savedPhoneNumber');

        setFormData({
            ...formData,
            phone: '',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.city || !formData.phone || !verificationCode) {
            alert('Заполните все поля.');
            return;
        }

        try {
            const response = await requestUserDelete({
                city: cityData.url,
                phone: formData.phone,
            });

            if (response.data.success) {
                alert('Запрос на удаление отправлен.');
                setFormData({ city: '', phone: '' });
                setIsCodeSent(false);
                setCityData(null);
                setVerificationCode('');
            } else {
                alert('Ошибка при отправке запроса. Попробуйте позже.');
            }
        } catch (error) {
            console.error('Ошибка при запросе на удаление пользователя:', error);
            alert('Произошла ошибка. Попробуйте позже.');
        }
    };

    if (!currentCity) {
        return <Navigate to="/404" />;
    }

    return (
        <Layout>
            <section className="section_user_delete">
                <div className="row justify-content-center m-5">
                    <div className="bg-grey-1 col-sm-12 col-md-6 q-mb-lg p-5" style={{ maxWidth: '600px' }}>
                        <h1 className="section_header mb-4">Запрос на удаление данных пользователя для приложения <br /> {currentCity.appName}</h1>
                        <p>Внимание, в форме ниже вы можете запросить удаление всех пользовательских данных в приложении {currentCity.appName}, привязанных к номеру телефона. 
                            Для подтверждения удаления вам придет sms на указанный номер. 
                            Удаление данных происходит моментально и восстановление данных после этого невозможно. 
                            Будьте внимательны.</p>
                        <form onSubmit={handleSubmit} className="delete_form">
                            <div className="col-12 q-pa-sm mb-3 mt-3">
                                <div className="row">
                                    <div className="col-12 col-sm-5" style={{ minWidth: '165px' }}>
                                        <CustomInput
                                            label="Ваш номер телефона"
                                            id="phone"
                                            name="phone"
                                            value={formData.phone}
                                            additionalClass={isUserLoggedIn ? 'q-field--filled' : ''}
                                            inputMask="+7(999) 999-99-99"
                                            isDisabled={isCodeSent || isUserLoggedIn}
                                            isRequired={true}
                                            onChange={handleInputChange} />
                                    </div>
                                    <div className="col-12 col-sm-7 q-pl-sm q-pt-sm">
                                        <button
                                            className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-hoverable"
                                            tabIndex="0" type="button"
                                            onClick={isUserLoggedIn ? handleExit : handleSendCode}>
                                            <span className="q-focus-helper"></span>
                                            <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                                                {isUserLoggedIn ? 'Выход' : 'Выслать код подтверждения'}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {isCodeSent && (
                                <div className="row q-pa-sm align-items-center">
                                    <div className="col-3">
                                        <CustomInput
                                            label="Введите код"
                                            id="code"
                                            name="code"
                                            inputMask="9999"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)} />
                                    </div>
                                    <div className="col-3 q-pl-sm q-pt-xs">
                                        <button
                                            className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-btn--actionable q-focusable q-hoverable"
                                            tabIndex="0"
                                            type="button"
                                            onClick={handleConfirmCode}
                                        >
                                            <span className="q-focus-helper"></span>
                                            <span className="q-btn__content text-center col items-center q-anchor--skip justify-content-center row">Подтвердить</span>
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div className="col-12 d-flex justify-content-center">
                                <button
                                    className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle bg-orange text-white q-hoverable q-mt-md"
                                    tabIndex="0" type="submit">
                                    <span className="q-focus-helper"></span>
                                    <span
                                        className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                                        <span className="block">Запросить удаление данных пользователя</span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default RequestForUserDeleteCityPage;
